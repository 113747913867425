import React from "react";

let About = () => {
  return (
    <div className="about">
      <h1>Pedro Montesinos Navarro es muy mono y un encanto</h1>
    </div>
  );
};

export default About;
