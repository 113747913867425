import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { Grid } from "@mui/material";
import Home from "./Routes/home/Home";
import About from "./Routes/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Projects from "./Routes/Projects";

let App = () => {
  return (
    <Router>
      <Grid container direction="row" style={{ height: "100vh" }}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/Projects" element={<Projects />} />
            </Routes>
            <Outlet />
          </main>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Router>
  );
};

export default App;
