import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "./home.css";

let Home = () => {
  let homeUrl = window.location.href;

  var presentacion = [
      "Hi, I'm Pedro, a professional web developer with experience in full stack and multiplatform development, but my true passion is front-end, UX and UI design.",
    ],
    part,
    i = 0,
    offset = 0,
    len = presentacion.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 50;

  const wordFlick = () => {
    let intervalId = setInterval(function () {
      if (window.location.href !== homeUrl) {
        clearInterval(intervalId);
        return;
      }
      if (forwards) {
        if (offset >= presentacion[i].length) {
          ++skip_count;
          if (skip_count === skip_delay) {
            forwards = false;
            skip_count = 0;
          }
        }
      } else {
        if (offset === 0) {
          forwards = true;
          i++;
          offset = 0;
          if (i >= len) {
            i = 0;
          }
        }
      }
      part = presentacion[i].substr(0, offset);
      if (skip_count === 0) {
        if (forwards) {
          offset++;
        } else {
          document.querySelector(".cursor").style.visibility = "visible";
          clearInterval(intervalId);
        }
      }
      const cursor = offset % 2 ? "▇" : "";
      document.querySelector(".presentation-text").textContent = part + cursor;
    }, speed);
  };

  useEffect(() => {
    document.querySelector(".presentation-text").textContent = "";
    wordFlick();
  });

  return (
    <div className="home">
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
            width: "inherit",
            mt: "1vh",
          }}
        >
          <div className="presentation-text-container">
            <span className="presentation-text"></span>
            <span className="cursor">▇</span>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            width: "inherit",
            mt: "1vh",
          }}
        >
          <img
            className="foto-de-pedro"
            src="fotoPresentacion.jpg"
            alt="Yo, en toda mi gloria"
            style={{ width: "90%" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
