import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Switch,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";

let Header = () => {
  const [open, setOpen] = useState(false);

  //Posible mejora handleDrawer que simplemente haga un flip del open state !open

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <header className="computer-view-header">
        <div className="header-logo">
          <img src="/pmon.png" alt="logo" className="header-image" />
        </div>
        <div className="header-links">
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Link className="header-link" sx={{ minWidth: 100 }} to="/">
              Home
            </Link>
            <Link className="header-link" sx={{ minWidth: 100 }} to="/About">
              About
            </Link>
            <Link className="header-link" sx={{ minWidth: 100 }} to="/Projects">
              Proyects
            </Link>
          </Box>
        </div>
        <div className="darkmode-toggler-container">
          <Switch className="darkmode-toggler"></Switch>
        </div>
      </header>
      <header className="phone-view-header">
        <AppBar
          className="mui-appbar"
          position="static"
          style={{ boxShadow: "none" }}
          sx={{ backgroundColor: "#00000000" }}
        >
          <Toolbar sx={{ justifyContent: "center" }}>
            <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
              <img src="pmon.png" alt="firma" className="logo-firma-mobile" />
            </IconButton>
          </Toolbar>
          <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
            <List>
              <ListItem>
                <Link
                  className="header-link"
                  sx={{ minWidth: 100 }}
                  to="/"
                  onClick={handleDrawerClose}
                >
                  Home
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  className="header-link"
                  sx={{ minWidth: 100 }}
                  to="/About"
                  onClick={handleDrawerClose}
                >
                  About
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  className="header-link"
                  sx={{ minWidth: 100 }}
                  to="/Projects"
                  onClick={handleDrawerClose}
                >
                  Proyects
                </Link>
              </ListItem>
            </List>
          </Drawer>
        </AppBar>
      </header>
      <Divider className="division" />
    </div>
  );
};

export default Header;
