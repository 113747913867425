import React from "react";

let Projects = () => {
  return (
    <div className="home">
      <h1>
        Aquí enumeraré los proyectos que vaya haciendo, para mi o para la
        empresa
      </h1>
    </div>
  );
};

export default Projects;
